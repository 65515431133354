import React from 'react';
import style from './Services.module.css';
import ServicesCard from '../Cards/ServicesCard';
import { faSnowflake, faFireAlt, faToolbox } from '@fortawesome/free-solid-svg-icons';
import img1 from '../../images/services1.jpeg';
import img2 from '../../images/services2.jpeg';
import img3 from '../../images/services3.jpeg';

const Services = () => {
    return (
        <div id="services" className={style.Container}>
            <div className="wrapper">
                <div className={style.Services}>
                    <h1>Our services</h1>
                    <div className={style.CardSection}>
                        <ServicesCard
                            img={img1}
                            icon={faSnowflake}
                            title="Cooling Services"
                            content="Our repair technicians are trained to work on the top brands in the industry. If your AC unit has stopped working completely or is not blowing cold air, call us today!"
                        />
                        <ServicesCard 
                            img={img2}
                            icon={faFireAlt}
                            title="Heating Services"
                            content="Having your boiler or furnace break during the winter can be stressful. If your furnace has already broke or is just making weird noises, give us a call today!"
                        />
                        <ServicesCard 
                            img={img3}
                            icon={faToolbox}
                            title="Other Services"
                            content="We fix everything related to HVAC and not just what you see here. We repair and install thermostats, ductless AC units, heat pumps, air handlers, and more!"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Services;