import React from 'react';
import style from './About.module.css';
import AboutCard from '../Cards/AboutCard';
import Button from '../Button/Button';
import { faWallet, faThumbsUp, faHandshake, faBusinessTime, faTools } from '@fortawesome/free-solid-svg-icons';

const About = () => {
    return (
        <div id="about" className={style.Container}>
            <div className="wrapper">
                <div className={style.CardSection}>
                    <AboutCard 
                        icon={faWallet}
                        title="No Upfront Payments"
                    />
                    <AboutCard 
                        icon={faThumbsUp}
                        title="Satisfaction Guarantee"
                    />
                    <AboutCard 
                        icon={faBusinessTime}
                        title="Emergency Service"
                    />
                    <AboutCard 
                        icon={faHandshake}
                        title="Fixed Right Promise"
                    />
                    <AboutCard 
                        icon={faTools}
                        title="10+ Years Experience"
                    />
                </div>
                <div className={style.AboutCompany}>
                    <div className={style.ImageContainer}>
                        <div className={style.Image}></div>
                    </div>
                    <div className={style.Text}>
                        <h4>HVAC Master Heating and Cooling</h4>
                        <h1>The Furnace and AC Repair Experts</h1>
                        <p>Master Finish is a small independent family business, a business that strives to do right by their customers, creating good solid relationships with satisfied clients and a supportive, loyal staff. We believe in the beauty of each individual project. It is our intention to create each request into a beautiful, strong, functional outcome that will enhance enjoyment of life.</p>
                        <Button link="https://facebook.com/HVACmasterIL" label="Learn more"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;