import React from 'react';
import style from './CallToAction.module.css';
import Button from '../Button/Button';

const CallToAction = () => {
    return (
        <div className={style.Container}>
            <div className="wrapper">
                <div className={style.CallToAction}>
                    <h1>No overtime charges, 7 days a week</h1>
                    <Button link="#contact" color={{ color: "white", background: "#38373C"}} label="Get a free quote"/>
                </div>
            </div>
        </div>
    );
};

export default CallToAction;