import React from 'react';
import style from './ServicesCard.module.css';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ServicesCard = (props) => {
    return (
        <div className={style.CardItem}>
            <div className={style.ImageDetails}>
                <img src={props.img} alt="itemcard"/>
                <div className={style.IconBox}>
                <FontAwesomeIcon
                    icon={props.icon}
                />
                </div>
            </div>
            <div className={style.ServiceDetails}>
                <h2>{props.title}</h2>
                <p>{props.content}</p>
                <Button link={"https://facebook.com/HVACmasterIL"} color={{ background: "#F1F1F1"}} label="Learn More"/>
            </div>
        </div>
    );
};

export default ServicesCard;