import React from 'react';
import style from './Gallery.module.css';
import galleryimg1 from '../../images/62239352_2369284409804118_5521527960516952064_n.jpg';
import galleryimg2 from '../../images/74284707_2718380098227879_103927088892870656_o.jpg';
import galleryimg3 from '../../images/64368194_2378036848928874_1092508464556539904_n.jpg';
import galleryimg4 from '../../images/56927276_2272473086151918_1834642559486394368_n.jpg';
import galleryimg5 from '../../images/47377774_2084498554949373_4265069324277907456_n.jpg';
import galleryimg6 from '../../images/64733890_2378036975595528_1953258372107075584_n.jpg';

const Gallery = () => {
    return (
        <div className={style.Container}>
            <img alt='galleryimg' src={galleryimg1} />
            <img alt='galleryimg' src={galleryimg2} />
            <img alt='galleryimg' src={galleryimg3} />
            <img alt='galleryimg' src={galleryimg4} />
            <img alt='galleryimg' src={galleryimg5} />
            <img alt='galleryimg' src={galleryimg6} />
        </div>
    );
};

export default Gallery;